// 외부 모듈
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from '@reducers/index';
import { useFirestore } from 'react-redux-firebase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// 내부 모듈
import KegItem from '@components/Keg';

// 타입
import { Keg, Offset, Offsets, PaymentWay, Pipe, Policies, Pump, Status, VendingMachine } from '@definitions/vm';
import { Container } from '@definitions/container';
import PumpItem from './Pump';
import VmStatus from './Status';

// 리덕스
import { selectVm } from '@reducers/selectedVm';
import VmPolicies from './VmPolicies';
import VmPaymentWays from './VmPaymentWays';
import VmTabs from './VmTabs';
import { Button } from '@material-ui/core';
import Instructions from './Instructions/Instructions';
import { DEPRICATED_VMS } from '@constants/vm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// 비교 함수
const compareMountPosition = (left: Keg | Pump, right: Keg | Pump) => left.mountPosition - right.mountPosition;

// 탬 패널
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function VendingMachines(): JSX.Element {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const containers: Container[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/containers'],
  );
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const isRootAdmin = admins[uid].level === 0;
  const isOperatingAdmin = admins[uid].level === 1;
  const isStoreManager = admins[uid].level === 2;
  const { selectedVm } = useSelector((state: RootStateType) => state.vm);
  const [editable, setEditable] = useState(true);
  const [history, setHistory] = useState('');

  const [cleaned, setCleaned] = useState<number[]>([]);

  useEffect(() => {
    if (selectedVm) {
      const vm = vms.find((vm) => vm.id === selectedVm);
      const mps = vm ? [...vm.kegs, ...vm.pumps].map((container) => container.mountPosition) : [];
      const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      const now = new Date();
      const ref = firestore
        .collection(`version/v3/vms/${selectedVm}/tasks`)
        .where('timeStamp', '>=', new Date(now.getTime() - sevenDaysInMilliseconds));
      const mps_: number[] = [];
      ref.get().then((snapshot) => {
        console.log(snapshot.docs.length);
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          if (data.type === 'cip' && mps.includes(data.mountPosition)) {
            mps_.push(data.mountPosition);
          }
        });
        setCleaned(mps_);
        console.log(mps_);
      });
    }
  }, [selectedVm]);

  const allVms = vms
    .filter((vm) => {
      const { id } = vm;
      if (DEPRICATED_VMS.includes(id)) {
        return false;
      }
      if (isRootAdmin) {
        //rootAdmin은 전체 vm 셀렉 가능하게끔한다
        return true;
      } else if (isOperatingAdmin) {
        //Operating Admin은 전체 test vm 제외 셀렉 가능하게끔한다
        return !id.includes('TEST') && !id.includes('INIT');
      } else if (isStoreManager) {
        // 점주들은 자신이 관리하는 기기만 확인할 수 있어야함.
        return admins[uid].vms.includes(id);
      }
    })
    .map((vm) => ({ id: vm.id, location: vm.location.name }));

  const handleVmSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    // 선택한 vm을 redux store로 관리하여 상태 유지시킴
    dispatch(selectVm(event.target.value as string));
    setEditable(true);
  };

  const clear = (id: string) => {
    const mountVm = vms.filter((vm) => vm.id === id)[0];

    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    const status: Status = {
      connect: {
        pcb: true,
        payment: true,
        admin: mountVm.status.connect.admin,
      },
      hw: {
        boilerTempSensor: true,
        floatingSensor: true,
        cupDispenser: [
          { plate: true, splitter: true },
          { plate: true, splitter: true },
          { plate: true, splitter: true },
        ],
        ir: true,
        kegs: [true, true, true, true, true, true],
        pumps: [true, true, true, true],
        water: true,
      },
      sw: {
        saga: true,
      },
    };
    fsRef.update({
      ...mountVm,
      status,
    });
  };

  const policiesHandler = (id: string, policies: Policies) => {
    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    fsRef.update({ policies });
  };

  const paymentWaysHandler = (id: string, paymentWays: PaymentWay[]) => {
    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    fsRef.update({ paymentWays });
  };

  const adminConnectHandler = (id: string, status: any) => {
    const fsRef = firestore.collection(`version/v3/vms/`).doc(id);
    fsRef.update({ status });
  };

  const locationHandler = (id: string, location: any) => {
    const fsRef = firestore.collection(`version/v3/vms/`).doc(id);
    fsRef.update({ location });
  };

  const modulesHandler = (id: string, modules: any) => {
    const fsRef = firestore.collection(`version/v3/vms/`).doc(id);
    fsRef.update({ modules });
  };

  const oprHisotryHandler = (id: string, oprHistory: string) => {
    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    fsRef.update({ 'location.oprHistory': oprHistory });
  };

  const offsetHandler = (id: string, offsets: Offsets) => {
    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    fsRef.update({ offsets });
  };

  const pipesHandler = (id: string, pipes: Pipe[]) => {
    const fsRef = firestore.collection('version/v3/vms/').doc(id);
    fsRef.update({ 'cleaning.pipes': pipes });
  };

  if (admins[uid] === undefined) {
    return <ul css={vms__wrap} />;
  }

  return (
    <ul css={vms__wrap}>
      <div css={input__wrap}>
        <FormControl style={{ width: '90%' }}>
          <InputLabel style={{ fontSize: '35px', marginBottom: '5px' }}>찾고자 하는 VM 명</InputLabel>
          <Select
            style={{ fontSize: '35px', paddingTop: '15px' }}
            value={selectedVm}
            defaultValue=""
            onChange={handleVmSelect}
          >
            {allVms.map((vm, idx) => (
              <MenuItem style={{ fontSize: '35px' }} key={idx} value={vm.id}>
                {vm.id}:{vm.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {vms
        .filter(({ id }) => {
          if (selectedVm === '') {
            if (isStoreManager) {
              return admins[uid].vms.includes(id);
            } else {
              true;
            }
          } else {
            return id === selectedVm;
          }
        })
        .map(
          ({
            id,
            kegs,
            pumps,
            status,
            policies,
            paymentWays,
            // location: { name, oprHistory, ip, connectionType, milkType },
            location,
            modules,
            offsets,
            cleaning,
          }) => {
            return (
              <li key={id} css={vm__item}>
                <h1>
                  {id}({location.name})
                </h1>
                <TextField
                  id="outlined-multiline-static"
                  label="특이사항"
                  multiline
                  rows={7}
                  style={{ width: '100%', margin: 0 }}
                  inputProps={{ style: { fontSize: '40px', lineHeight: '45px', marginTop: '15px' } }}
                  InputLabelProps={{ style: { fontSize: '35px', height: '50px' } }}
                  defaultValue={location.oprHistory}
                  onChange={(event) => setHistory(event.target.value)}
                  variant="filled"
                  disabled={editable}
                />
                {editable ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{ width: '100%', marginRight: 0 }}
                    onClick={() => {
                      const found = vms.find(({ id }) => id === selectedVm);
                      if (!found || !found.location) {
                        window.alert('선택된 vm 또는 location 정보가 존재하지 않습니다.');
                        return;
                      }
                      if (!found.location.oprHistory) {
                        setHistory('');
                      } else {
                        setHistory(found.location.oprHistory);
                      }

                      setEditable(!editable);
                    }}
                  >
                    수정하기
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    style={{ width: '100%', marginRight: 0 }}
                    onClick={() => {
                      oprHisotryHandler(id, history);
                      setEditable(!editable);
                    }}
                  >
                    저장하기
                  </Button>
                )}

                <div css={vm__item__kegs}>
                  <h1>커피</h1>
                  {[...kegs].sort(compareMountPosition).map((keg) => {
                    const mountPosition = keg.mountPosition;
                    const offset = offsets.kegs.find((k) => k.mountPosition === mountPosition);
                    const pipe = cleaning.pipes.find((p) => p.pipeNum === mountPosition);
                    return (
                      <KegItem
                        vmId={id}
                        key={keg.mountPosition}
                        keg={keg}
                        ingredients={ingredients}
                        containers={containers}
                        isCleaned={cleaned.includes(keg.mountPosition)}
                        ip={location.ip || 'localhost'}
                        offset={offset}
                        pipe={pipe}
                        updateOffset={(offset: Offset) =>
                          offsetHandler(id, {
                            ...offsets,
                            kegs: offsets.kegs.map((o) => {
                              if (o.mountPosition === offset.mountPosition) {
                                return offset;
                              }

                              return o;
                            }),
                          })
                        }
                        updatePipes={(pipe: Pipe) => {
                          pipesHandler(
                            id,
                            cleaning.pipes.map((p) => {
                              if (p.pipeNum === pipe.pipeNum) {
                                return pipe;
                              }

                              return p;
                            }),
                          );
                        }}
                      />
                    );
                  })}
                </div>
                <div css={vm__item__kegs}>
                  <h1>시럽 & 우유</h1>
                  {[...pumps].sort(compareMountPosition).map((pump) => {
                    const mountPosition = pump.mountPosition;
                    const offset = offsets.pumps.find((p) => p.mountPosition === mountPosition);
                    const pipe = cleaning.pipes.find((p) => p.pipeNum === mountPosition);

                    return (
                      <PumpItem
                        containers={containers}
                        key={pump.mountPosition}
                        vmId={id}
                        pump={pump}
                        ingredients={ingredients}
                        isCleaned={cleaned.includes(pump.mountPosition)}
                        ip={location.ip || 'localhost'}
                        offset={offset}
                        pipe={pipe}
                        updateOffset={(offset: Offset) =>
                          offsetHandler(id, {
                            ...offsets,
                            pumps: offsets.pumps.map((o) => {
                              if (o.mountPosition === offset.mountPosition) {
                                return offset;
                              }

                              return o;
                            }),
                          })
                        }
                        updatePipes={(pipe: Pipe) => {
                          pipesHandler(
                            id,
                            cleaning.pipes.map((p) => {
                              if (p.pipeNum === pipe.pipeNum) {
                                return pipe;
                              }

                              return p;
                            }),
                          );
                        }}
                      />
                    );
                  })}
                </div>
                {/* <div css={tabs__wrap}>
                <Tabs value={tabIdx[id]} onChange={tabHandler} indicatorColor="primary" textColor="primary">
                  <Tab label="Status" />
                  <Tab label="Policies" />
                  <Tab label="PaymentWays" />
                </Tabs>
                <TabPanel value={tabIdx[id]} index={0}>
                  <VmStatus status={status} clear={() => clear(id)} />
                </TabPanel>
                <TabPanel value={tabIdx[id]} index={1}>
                  <VmPolicies policies={policies} policiesHandler={policiesHandler} id={id} />
                </TabPanel>
                <TabPanel value={tabIdx[id]} index={2}>
                  <VmPaymentWays paymentWays={paymentWays} paymentWaysHandler={paymentWaysHandler} id={id} />
                </TabPanel>
              </div> */}
                <VmTabs
                  id={id}
                  ip={location.ip || 'localhost'}
                  connectionType={location.connectionType || 'grpc'}
                  policies={policies}
                  status={status}
                  paymentWays={paymentWays}
                  clear={clear}
                  policiesHandler={policiesHandler}
                  paymentWaysHandler={paymentWaysHandler}
                  adminConnectHandler={adminConnectHandler}
                  modules={modules}
                  containers={[...kegs, ...pumps]}
                  dispensers={modules.filter((md) => md.type === 'dispenser')}
                  location={location}
                  locationHandler={locationHandler}
                  modulesHandler={modulesHandler}
                />
              </li>
            );
          },
        )}
    </ul>
  );
}

const vms__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);

  display: flex;
  flex-direction: column;
  background-color: #f8f7f5;
  scrollbar-width: none;
  box-sizing: border-box;
  overflow-y: auto; /* 세로 스크롤 적용 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
`;

const input__wrap = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const vm__item = css`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  h1 {
    font-size: 56px;
    font-weight: bold;
    padding-bottom: 12px;
  }
  button {
    height: 120px;
    color: white;
    margin-right: 22px;
    font-size: 32px;
    border: none;
  }
`;

const vm__item__kegs = css`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px dashed #dddddd;
  border-bottom: 1px solid #dddddd;
  h1 {
    width: 100%;
    background-color: #666666;
    color: white;
    padding-left: 24px;
    font-weight: bold;
    font-size: 46px;
  }
`;

const tabs__wrap = css`
  width: 100%;

  .MuiTab-textColorPrimary {
    color: black;
    background-color: #fff;
    margin-right: 5px;
  }
`;

export default VendingMachines;
